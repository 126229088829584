<template>
    <div id="medicine-list-section" class="create-section mt-sm-5 mt-3">
        <div class="container">
            <div class="row">
                <div class="col-md-8">
                    <form @submit.prevent="editMode ? update() : save()">
                        <div class="mb-3 row">
                            <div class="col-sm-4">
                                <drop-down :class_name="{ 'is-invalid': errors['patient_id'] }" title="Patient"
                                           v_model="patient"/>
                                <div v-if="errors['patient_id']" class="invalid-feedback">
                                    {{ errors['patient_id'][0] }}
                                </div>
                            </div>
                            <div class="col-sm-2">
                                <select required :class="{ 'is-invalid': errors['rating'] }" v-model="form.rating"
                                        class="form-control mb-3">
                                    <option value="0">0</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                </select>
                                <div v-if="errors['rating']" class="invalid-feedback">
                                    {{ errors['rating'][0] }}
                                </div>
                            </div>
                            <div class="col-sm-5">
                                <textarea :class="{ 'is-invalid': errors['message'] }" placeholder="Message" required
                                          v-model="form.message" type="text"
                                          class="form-control mb-3" id="message"></textarea>
                                <div v-if="errors['message']" class="invalid-feedback">
                                    {{ errors['message'][0] }}
                                </div>
                            </div>
                            <div class="col-sm-1 p-0">
                                <input type="submit" class="btn btn-primary"
                                       :value="editMode ? 'Update' : 'Save'">
                            </div>
                        </div>
                    </form>
                </div>
                <div class="col-md-4">
                    <form @submit.prevent="getList()">
                        <div class="mb-3 row">
                            <div class="col-sm-8 ms-auto">
                                <input v-model="table.search" type="text" class="form-control mb-3" id="name"
                                       placeholder="Searching...">
                            </div>
                            <div class="col-sm-4">
                                <input type="submit" class="btn btn-primary w-100" value="Searching">
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div>
                <div class="table-responsive">
                    <table class="table table-striped table-hover">
                        <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Patient</th>
                            <th scope="col">Rating</th>
                            <th scope="col">Message</th>
                            <th scope="col">Edit</th>
                            <th scope="col">Delete</th>
                        </tr>
                        </thead>
                        <tbody>
                        <template v-if="lists.length">
                            <tr v-for="(list, index) in lists">
                                <th scope="row">{{ table.pagination.from + index }}</th>
                                <td>{{ list.patient ? list.patient.name : '' }}</td>
                                <td>{{ list.rating }}</td>
                                <td>{{ list.message }}</td>
                                <td>
                                    <update-action type="modal" v-if="$root.$can('service', 'service-update')"
                                                   :id="list.id"/>
                                </td>
                                <td>
                                    <delete-action v-if="$root.$can('service', 'service-delete')" :id="list.id"/>
                                </td>
                            </tr>
                        </template>
                        <template v-if="!lists.length">
                            <tr>
                                <th class="text-center font-weight-bolder" colspan="100%">No data found</th>
                            </tr>
                        </template>
                        </tbody>
                    </table>
                </div>
            </div>
            <pagination v-if="lists.length>0" :pagination="table.pagination" @paginate="getList()" :offset="5"/>
        </div>
    </div>
</template>

<script>
import ApiService          from "@/services/api.service";
import NotificationService from "@/services/notification.service";
import Pagination          from "@/components/Pagination";

export default {
    name      : "ServiceIndex",
    components: {Pagination},
    data      : () => ({
        lists   : [],
        errors  : [],
        form    : {
            patient: '',
            rating : 5,
            message: "",
        },
        table   : {
            per_page  : 10,
            search    : '',
            pagination: {
                current_page: 1
            }
        },
        editMode: false
    }),
    mounted() {
        this.getList();
    },
    methods: {
        //-------CRUD------
        getList() {
            let params = {
                ...this.table,
                page: this.table.pagination.current_page,
            };
            ApiService.get('/testimonials', {params: params}).then((res) => {
                this.lists            = res.data.data;
                this.table.pagination = res.data.meta;
            }).catch(error => {
                NotificationService.error(error.response.data.message);
            })
        },
        save() {
            this.form.patient_id = this.form.patient ? this.form.patient.id : ''
            ApiService.post('/testimonials', this.form).then((res) => {
                this.errors = []
                this.getList();
                NotificationService.success(res.data.message);
                this.formReset();
            }).catch(error => {
                this.errors = error.response.data.errors;
                NotificationService.error(error.response.data.message);
            })
        },
        edit(id) {
            this.editMode = true;
            ApiService.get(`/testimonials/${id}`).then((res) => {
                this.form = res.data.data;
            }).catch(error => {
                NotificationService.error(error.response.data.message);
            })
        },
        update() {
            this.form.patient_id = this.form.patient ? this.form.patient.id : ''
            ApiService.update(`/testimonials/${this.form.id}`, this.form).then((res) => {
                this.errors = []
                NotificationService.success(res.data.message);
                this.formReset();
                let updatedItem        = res.data.data
                let foundIndex         = this.lists.findIndex(x => x.id == updatedItem.id);
                this.lists[foundIndex] = updatedItem;
                this.editMode          = false;
            }).catch(error => {
                this.errors = error.response.data.errors;
                NotificationService.error(error.response.data.message);
            })
        },
        destroy(id) {
            Swal.fire({
                title             : 'Are you sure?',
                text              : "You won't be able to revert this!",
                icon              : 'warning',
                showCancelButton  : true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor : '#d33',
                confirmButtonText : 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    ApiService.delete(`/testimonials/${id}`,).then(res => {
                        this.getList();
                        NotificationService.success(res.data.message);
                    }).catch(error => {
                        NotificationService.error(error.response.data.message);
                    })
                }
            }).catch(error => {
                NotificationService.error(error.response.data.message);
            })
        },
        formReset() {
            this.form = {
                patient_id: '',
                patient   : '',
                rating    : 5,
                message   : "",
            }
        }
        //-------CRUD--------
    }
}
</script>
